import { useEffect, useState} from 'react'
import { List, ListItem, ListItemText, Grid, ListItemIcon, Button, Container } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import { Fade } from 'react-reveal'
import { Link, useSearchParams } from "react-router-dom";
import { WhoHelmetQR, WhoHelmet } from '../HelmetTags';
import { Helmet } from 'react-helmet';


// tags for scrolling
const IndividualQRCodes = [
    ["bob", "00"],
    ["ray", "01"],
    ["mike", "02"],
    ["justin", "03"],
    ["dan", "04"],
    ["minivan", "05"],
    //["lauren", "06"],
    ["madeline", "07"],
    ["hannah", "08"],
]

const fadeDelay = 300

export const Who = (props) => {
    const [ fadeIn, setFadeIn] = useState(false)
    const [ curFade, setFade ] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setFade(true)
            setFadeIn(true)
        }, fadeDelay)
        // check qr tag prior to scrolling to top
        // scroll to individual tag 
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])


    // Scroll to specific
    const ScrollToQRID = (idToScrollTo) => {
        const targetID = `person-${idToScrollTo}`
        var itemByID = document.getElementById(`person-${idToScrollTo}`);
        if (itemByID == null) {
            console.log("[ qr person id not found ]")
            return
        }

        console.log(`[ specific person QR ] ${targetID}`)
        document.getElementById(targetID).scrollIntoView({
            behavior: 'smooth'
        });
    }

    // Adjust helmet on who page 
    const CorrectHelmet = (r) => {
        // default helmet
        if (r === null || r === undefined) {
            return  WhoHelmet
        }

        // split to get correct id 
        const qrID = r.replace("qr", "")
        
        // scan entries for current 
        var foundQRSpecific = false 
        var foundQRID = ""
        IndividualQRCodes.map(([name, key]) => {
            // each item check 
            if (qrID === key) {
                foundQRSpecific = true 
                foundQRID = name
                return 1
            }
            return 0
        })

        if (!foundQRSpecific) {
            return WhoHelmetQR
        } 

        // Found specific QR code 
        // ID found set 
        ScrollToQRID(foundQRID)

        // capitalize name 
        const capitalizedName = foundQRID.charAt(0).toUpperCase() + foundQRID.slice(1)

        // return helmet specific for person 
        return (
            <Helmet>
                <link rel="canonical" href="https://nsg-global.com/" />
                <title>The Next Solutions Group - Who | QR - {capitalizedName}</title>
            </Helmet>
        ) 
    }

    const [ searchParams ] = useSearchParams();
    const r = searchParams.get('r')
    return (
        <Container >
            { CorrectHelmet(r) }
            <Fade timeout={fadeDelay}>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                <h1 style={{fontSize: '3.2em'}}>Our Team</h1>
            </div>
            </Fade>

            <Grid container spacing={3} style={{justifyContent: 'center'}}>
                { 
                    AllTeam.map((person, i) => {
                    return (
                        <Grid item xs={12} md={4} style={{paddingBottom: '50px'}} key={i} id={`person-${person.id}`}>
                            <Fade in={curFade}  timeout={fadeDelay}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <img src={process.env.PUBLIC_URL + person.image}  alt={person.alt} style={{padding: '10px', width: '360px', height: '360px'}} />
                                <h2 className="WhoTitle">{ person.title }</h2>
                            </div>
                            </Fade>
                            <Fade in={curFade} timeout={fadeDelay}>
                                <List style={{display: 'flex', flexDirection: 'column'}}>
                                    { person.data.map((item, i) => {
                                        return (
                                            <ListItem disablePadding key={i}>
                                                <ListItemIcon>
                                                    <CheckIcon className="CheckIconColor" />
                                                </ListItemIcon>
                                                <ListItemText primary={ item } />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Fade>
                        </Grid>
                    )
                })}
            </Grid>
            
            { /**
            <Fade timeout={fadeDelay}>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', paddingTop: '75px'}}>
                <h1 style={{fontSize: '3.2em'}}>Our Chair</h1>
            </div>
            </Fade>

            <Grid container spacing={3} style={{justifyContent: 'center'}}>
                { 
                    AllChair.map((person, i) => {
                    return (
                        <Grid item xs={12} md={4} style={{paddingBottom: '50px'}} key={i} id={`person-${person.id}`}>
                            <Fade in={curFade}  timeout={fadeDelay}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <img src={process.env.PUBLIC_URL + person.image}  alt={person.alt} style={{padding: '10px', width: '360px', height: '360px'}} />
                                <h2 className="WhoTitle">{ person.title }</h2>
                            </div>
                            </Fade>
                            <Fade in={curFade} timeout={fadeDelay}>
                                <List style={{display: 'flex', flexDirection: 'column'}}>
                                    { person.data.map((item, i) => {
                                        return (
                                            <ListItem disablePadding key={i}>
                                                <ListItemIcon>
                                                    <CheckIcon className="CheckIconColor" />
                                                </ListItemIcon>
                                                <ListItemText primary={ item } />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Fade>
                        </Grid>
                    )
                })}
            </Grid>
            */}


            <Fade timeout={fadeDelay}>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', paddingTop: '75px'}}>
                <h1 style={{fontSize: '3.2em'}}>Our Advisors</h1>
            </div>
            </Fade>

            <Grid container spacing={3} style={{justifyContent: 'center'}}>
                { 
                    AllAdvisors.map((person, i) => {
                    return (
                        <Grid item xs={12} md={4} style={{paddingBottom: '50px'}} key={i} id={`person-${person.id}`}>
                            <Fade in={curFade}  timeout={fadeDelay}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <img src={process.env.PUBLIC_URL + person.image}  alt={person.alt} style={{padding: '10px', width: '360px', height: '360px'}} />
                                <h2 className="WhoTitle">{ person.title }</h2>
                            </div>
                            </Fade>
                            <Fade in={curFade} timeout={fadeDelay}>
                                <List style={{display: 'flex', flexDirection: 'column'}}>
                                    { person.data.map((item, i) => {
                                        return (
                                            <ListItem disablePadding key={i}>
                                                <ListItemIcon>
                                                    <CheckIcon className="CheckIconColor" />
                                                </ListItemIcon>
                                                <ListItemText primary={ item } />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Fade>
                        </Grid>
                    )
                })}
            </Grid>
            { /** End Advisors */}

            <Fade in={fadeIn} timeout={fadeDelay}>
            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '60px'}}>
                <Link to="/what" style={{textDecoration: 'none'}} className="noSelect">
                <Button sx={{ zIndex: '999', textTransform: 'uppercase', letterSpacing: '2px', my: 2, mx: 2, bgcolor: 'transparent', borderRadius: '0', border: '1px solid #FFF3', paddingLeft: '15px', marginBottom: '30px', paddingRight: '15px',  color: 'white', display: 'block', ':hover': {
                    bgcolor: '#FFF1', // theme.palette.primary.main
                    color: 'white', boxShadow: '1px 1px 3px #bbb', 
                } }} >What we do</Button>
                </Link>
            </div>
            </Fade>
        </Container>
    )
}

const PersonData = {
    bob: {name: "Bob Pearson", data: ["Senior Counselor", "Global Executive Leadership positions at Novartis and Dell", "Instructor to U.S. Government in Countering Disinformation"]},
    ray: {name: "Ray Kerins", data: ["Chief Executive Officer","Global Executive Leadership positions at Bayer, Pfizer, and Merck","Three decades of Reputation, Crisis, and Issues Management"]},
    mike: {name: "Mike Harley", data: ["Managing Director, Business & Cyber Intelligence","Former National Security Agency","Two decades of senior military intelligence"]},
    justinb: {name: "Justin Blum", data: ["Managing Director, Content and Issues Management","Former senior editor and reporter at Bloomberg News, Washington Post and St. Petersburg Times","Covered the White House, U.S. Congress, Justice Department, and industries including health care, energy, education, and financial regulation"]},
    dan: {name: "Dan Childs", data: ["Managing Director, Global Media Engagement","Former SVP of Healthcare Media Relations for Porter Novelli and Director of U.S. External Communications for Bayer","Former Managing Editor of the ABC News Medical Unit"]},
    carolyn: {name: "Carolyn Nagle", data: ["Vice President","Former Integrated Strategic Communications Manager at Bayer","Strong experience in Operations, External Communications, Women's Healthcare and Oncology"]},
    lauren: {name: "Lauren Pearle", data: ["Senior Vice President, Content and Issues Management","Former Senior Producer of ABC News Live, Producer with the ABC News Investigations Unit and Law & Justice Unit","Former Litigation Associate, Sidley Austin LLP and Davis & Gilbert LLP"]},
    justinm: {name: "Justin McCormick", data: ["Senior Director, Research & Development","Former National Security Agency","Cyberwarfare, Machine Learning and Artificial Intelligence"]},
    maddie: {name: "Madeline Brew", data: ["Senior Associate","Former Account Coordinator, LaForce","Research, Media Engagement, & Crisis Management"]},
    hannah: {name: "Hannah Hughes", data: ["Associate","B.A. Ithaca College Integrative Marketing Communications, Legal Studies Minor","Research & PR Engagement in Media"]},
}

const AllTeam = [
    {image: "images/people/ray.webp", alt: "Ray Kerins", title: PersonData.ray.name, data: PersonData.ray.data, id: "ray"},    // Ray
    {image: "images/people/mike.webp", alt: "Mike Harley", title: PersonData.mike.name, data: PersonData.mike.data, id: "mike"},    // Mike
    {image: "images/people/dan.webp", alt: "Dan Childs", title: PersonData.dan.name, data: PersonData.dan.data, id: "dan"},    // Dan
    {image: "images/people/justinb.webp", alt: "Justin Blum", title: PersonData.justinb.name, data: PersonData.justinb.data, id: "justin"},    // Justin
    {image: "images/people/carolyn-new.webp", alt: "Carolyn Nagle", title: PersonData.carolyn.name, data: PersonData.carolyn.data, id: "carolyn"},    // Dan
    {image: "images/people/justinm.webp", alt: "Justin McCormick", title: PersonData.justinm.name, data: PersonData.justinm.data, id: "minivan"}, 
    {image: "images/people/bobpearson.webp", alt: "Senior Counselor", title: PersonData.bob.name, data: PersonData.bob.data , id: "bob"},    // Bob
    {image: "images/people/maddie-new.webp", alt: "Madeline Brew", title: PersonData.maddie.name, data: PersonData.maddie.data, id: "madeline"},    // Maddy
    {image: "images/people/hannah-new.webp", alt: "Hannah Hughes", title: PersonData.hannah.name, data: PersonData.hannah.data, id: "hannah"},    // Hannah
]


const AdvisorData = {
    john: {name: "John Clark", data: ["Senior Advisor","Former Head of Global Security, Pfizer","Former Deputy Assistant Secretary Department of Homeland Security"]},
    david: {name: "David Gallagher", data: ["Senior Advisor","CEO, NextPurpose","Former President, Omnicom PR Group and CEO, Ketchum Europe"]},
}

const AllAdvisors = [
    {image: "images/people/john.webp", alt: "John Clark", title: AdvisorData.john.name, data: AdvisorData.john.data, id: "john"},    // John
    {image: "images/people/david.webp", alt: "David Gallagher", title: AdvisorData.david.name, data: AdvisorData.david.data, id: "david"},    // david

]