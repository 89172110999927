import { useEffect} from 'react'

import { Fade } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import { ContactHelmet } from '../HelmetTags';

export const Contact = (props) => {

    useEffect(() => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])

    return (
        <div>
            { ContactHelmet }
            <Fade in={true} timeout={800}>
            <div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <h3 style={{color: '#4DA8DA', textTransform: 'uppercase'}}>Get in Touch</h3>
                    <h1>Contact us</h1>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                    <EmailIcon size={25} style={{padding: '10px'}} />
                    <a href="mailto:engage@nsg-global.com" style={{textDecoration: 'none', color: '#4DA8DA'}}>engage@nsg-global.com</a>
                </div>
            </div>
            </Fade>
        </div>
    )
}

