import { Container, Grid } from "@mui/material"
import { useEffect} from 'react'
import { Fade } from "react-reveal"

export const CyberSecurityPage = () => {
    useEffect(() => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])

    return (
        <div>
            <Container>
                <Grid container padding={2}>
                    <Grid item xs={12} md={12}>
                        <Fade>
                            <h1>Cybersecurity & Investigations</h1>
                            <div style={{textAlign: 'center', paddingTop: '50px'}}>
                                For more information, contact us at <a href="mailto:engage@nsg-global.com" className="lightBlueText" >engage@nsg-global.com</a>
                            </div>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}