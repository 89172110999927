
export const THOUGHTS = {
    post_ai_title: "Improper use of ChatGPT will cost you",
    post_ai_content: `Advanced AI tools like ChatGPT can be incredible time-savers, but when they're used to cut corners, they can exact a heavy toll.\n\nThis week, a New York federal judge blasted two attorneys who relied on ChatGPT to write a legal brief that cited fake cases. Judge P.Kevin Castel ordered attorneys Peter LoDuca, Steven Schwartz, and their law firm to pay $5,000 each for the mishap and to notify each judge they falsely identified as the author of a bogus ruling.\n\nThe judge wrote that “good lawyers appropriately obtain assistance from junior lawyers, law students, contract lawyers, legal encyclopedias and databases such as Westlaw and LexisNexis.” Instead, LoDuca and Schwartz used AI as a sloppy short - cut that wound up damaging their reputations and wallets. The judge further reprimanded them for not coming “clean” about their reliance on ChatGPT when their case citing was initially questioned.\n\nWhile the size of these fines may not be significant, this case and the ridicule it sparked provides a cautionary tale.Tools like ChatGPT can be helpful, but they can't - at least  not yet - stand in for the hard work, due diligence, and time it takes for humans to ensure their work is accurate and reliable.`,    

    post_generative_ai_title: "Generative AI makes reputation management more critical than ever",
    post_generative_ai_content: `Generative AI tools like ChatGPT are becoming ever more integral to business operations. The transformation of corporate America is just beginning, but already we're seeing AI technologies boost productivity, efficiency, and cost-savings for companies of all sizes. Businesses that don't adopt generative AI (albeit smartly and with caution) risk losing out to those that do.\n\nBut as we know, these technologies also pose serious threats, among them, the ability to spread misinformation and disinformation at warp speed. AI tools can now create cloned human voices, highly realistic fake images, and fake stories in seconds, at minimal cost, and distribute them far and fast across the globe. Senator Richard Blumnethal (D-CT) demonstrated this trickery by opening a Senate hearing on AI with a deepfake of his own voice. And recently, a fan-made ad for Tesla used AI to create a deepfake of actor Ryan Reynolds purporting to promote the company. This is scary stuff.\n\nWhat's more, misinformation/ disinformation created by and spread through AI is now being captured by tools like ChatGPT that scour the open web, leading to “AI hallucinations.” Fake images, misinformation and misstatements have become harder than ever to purge from the public domain, especially when they've gone viral.\n\nIn this climate, it's more critical than ever for companies to stay vigilant about reputation management. The Next Solution Group, a crisis communications and issues management firm, recommends that companies:\n
    1. Regularly monitor news and information about the company and their products.
    2. When appropriate, monitor not just the open web but also the deep and dark web.
    3. Combat misinformation and disinformation as soon as it's discovered, before it spreads further.
    4. Be aware of how AI can be weaponized and make a crisis plan for AI-related issues that could arise.
    5. Understand how your company utilizes AI. Use AI tools responsibly and in line with your corporate values and proceed with some caution when adopting new AI systems.\n`,


    post_nav_corp_partnerships_title: "Navigating Corporate Partnerships Involving Saudi Arabia",
    post_nav_corp_partnerships_content: `The PGA's recent agreement to merge with its Saudi-funded competitor LIV Golf highlights a challenge for U.S. companies: the need to communicate openly about entering a partnership involving a country whose leadership has been linked to human rights abuses. 

    The golf merger, backed by a $650 billion sovereign wealth fund controlled by Saudi Crown Prince Mohammed bin Salman, quickly came under criticism from lawmakers, columnists, and activists. Among other things, they cited executions and imprisonments of political dissidents, along with the slaying of Washington Post journalist Jamal Khashoggi at the hands of Saudi agents in 2018. Family members of the victims of the Sept. 11 attacks also expressed outrage; for years, many of them have raised questions about Saudi support for the terrorists even as the kingdom has denied a role in the attacks.
    
    Other U.S. companies should take note. If companies decide to engage in partnerships with the Saudi government, accept funding from government-controlled funds, or establish offices in the kingdom, it is essential to create a communications plan that lays out how to directly address the criticism that is likely to follow.
    
    The PGA appeared to be caught flat footed by the criticism - without a meaningful response or explanation of how the merger aligned with its values. In the absence of an explanation, a chorus of critics filled the void, leaving the PGA's reputation badly tarnished.
    
    Companies need to tell their employees how alliances with the kingdom square with their values. They need to be able to explain their actions to customers, shareholders, and partners. They need to detail any benefits that could come from the new relationship - beyond profit.
    
    For instance, if a U.S. hospital system opens a location in Saudi Arabia, it may say that its aim is to provide life-saving treatments to people who otherwise might not have access. The organization may also say its decision shouldn't be seen as a sign of support for the government. They may go a step further and announce plans to donate a percentage of their proceeds to human rights groups.
    
    Such messages won't necessarily inoculate firms from criticism. But transparent explanations provide a counterpoint to assertions from critics - and may help forestall boycotts, resignations and severe reputational damage.`,    

    post_tipsjournalism_title: "So, You're Leaving Journalism for Communications: Tips for Journalists Making the Switch",
    post_tipsjournalism_content: `“So, what's it like to make the switch?”

    I've had quite a few journalists reach out to me for a conversation about “the switch” to a career in communications in recent months. Given the big changes we have been seeing in so many high-profile news organizations, it's little surprise that some great journos are looking for advice on that next step that will take them out of the newsroom.
    
    I remember well how it felt to be on the other side of those same conversations several years ago, when I considered (and ultimately made) this career change. Here are a few things I wish I had heard when I made the leap from journalism to communications:
    
    Get ready to wear different hats. As a former journalist in the comms setting, your new colleagues will be calling upon you to share the skills and knowledge you developed as a newsperson. You might be asked about the viability of a news angle, or how a journalist might react to a pitch. These are valuable insights for your new colleagues - but remember that there will be additional value you will be expected to add if you want to thrive. Expect to develop new muscles in building narratives, offering high-level counsel, and providing excellent client service. You will need to be more than just the “resident ex-journalist” to succeed.
    
    “Calling in a favor” is not a strategy. It's a trap. A weak pitch will always be a weak pitch and trying to leverage a professional or personal relationship to get it off the ground doesn't do anyone any favors - not your client, not your firm or company, not your reporter friend (who will no doubt feel awkwardly put upon) … and certainly not yourself. Expect this request, and to be prepared to suggest a better approach to your team or your client.
    Maintaining relationships, however, is clutch. Thankfully, as a former journalist, you will know a good pitch when you see it - and the truth is that the difference between it stalling or succeeding may indeed rely on that journalist with whom you've worked. Nurture these relationships. Check in from time to time, even when you are not pitching a story. Your professional relationships with your former colleagues will change; it's up to you to make sure they do not evaporate.
    
    Feed your passion. A question I often get from journalists considering a career change is whether I miss journalism or not. The answer: Of course, I do - and you will, too. Whether it's the adrenaline of the newsroom environment or that feeling of breaking the big story to an interested audience, that thrill is addictive, and it is impossible to forget. That's why it's so important to make certain that your role in communications is in the service of something that gets you excited, and that the nature of the work you will be doing will be stimulating enough to maintain your interest.
    
    In my current position with The Next Solutions Group, I get to help great clients protect and advance their reputation, while working alongside a world-class team of former senior-level journalists, public relations veterans and government relations trailblazers, and former military and government specialists in electronic communications and cybersecurity. I couldn't be happier with my job. If you are a journalist reading this because you are thinking about making the switch, my hope is that you land at a similar organization, which allow you to make the most of your talents while learning new skills along the way.
    
    And how do you find the role that fits your interests, your skills and your goals? My advice: Confirm your expectations during your interviews for the job. To the best of your ability, interview with the people to whom you will be reporting and with whom you will be working. Help them understand the deep skill set you bring to the table and be open about the skills you want to develop. More than anything else, setting these mutual expectations and growing into your new career will make the difference between a job you will hold down for a few months and a career you will enjoy for years to come.`,

    post_culturewars_title: "To Survive the Culture Wars, Companies Should Define and Live Their Values",
    post_culturewars_content: `The old adage that “any press is good press” is dead wrong when a company becomes a casualty of America's culture wars.

    Just look at Target, which faced a fierce backlash for its Pride collection and prominent LGBTQ+ store displays, particularly in the children's section. Target reacted to the outrage by removing some Pride themed products and relocating Pride Month displays to the back of stores in some locations. Predictably, that response - seen as caving to anti-LGBTQ+ activists - sparked the fury of those on the opposite side of the cultural battle. The result has been a devastating lose, lose situation and a jaw-dropping $9 billion free fall of market cap.
    
    The Target blunder of course echoes the recent Bud Light fiasco. The company drew outrage for its partnership with trans activist Dylan Mulvaney and then abruptly reversed course, seemingly under pressure from its critics. The entire episode was chaotic and misguided, and it cost the beer giant billions of dollars and lasting reputational damage.
    
    There's a much better way for companies to navigate these fraught cultural issues, and that's to define and then truly live one's corporate values. For example, if your company wants to support LGBTQ+ people, then make tangible, meaningful contributions to that community and when appropriate, showcase your support while staying steadfast to your principles.
    
    In Target's case, If the original Pride-themed products and displays reflected the company's values, then it shouldn't back down under pressure. Also, Target could have been more transparent about its decision-making and better prepared for the uproar on both sides.
    
    On the flip side, customers and stakeholders generally see through empty “virtue signaling” or pandering to specific communities for business, marketing or PR gain.
    
    Put simply: a company's values need to drive its decision-making when it comes to business and to how the company responds to its critics during times of crisis. In life and in business, values, ethics, and morals can lead the way.`,

    post_mediatraining_title: "Here's How to Tell Your Boss They Need Media Training",
    post_mediatraining_content: `You know your boss needs help. Every time you set up a TV interview with the executive, something goes wrong - despite hours of preparation.

    The executive's message gets overshadowed by a meandering answer. When questions become challenging, her frustration visibly boils over. Her resulting facial expressions tell viewers she has something to hide. Instead of a helpful exchange, the interview turns into a contentious meltdown. The sound bites that wind up in the story are totally off message. The interview casts the company in a negative light and make board members wince.
    
    At this point, many executives will recognize they need help - and ask for it. But your boss blames the interviewer and doesn't acknowledge any room for personal improvement. You know you have to find a way to turn things around. The status quo can't continue.
    
    But how do you persuade the recalcitrant executive that it's time for professional help?
    
    First, give the executive time to cool down. Let some time pass after the offending interview airs. Then find a time when you can get your boss's undivided attention.
    
    Begin with a non-threatening conversation. Compliment your boss on the excellent work she has done. But suggest that those accomplishments didn't come across in the TV interview the way they should have.
    
    Be specific about your concerns. Focus on the message - the need to deliver a clear response that's on topic, with proof points to back up assertions - and the importance of returning to those messages even when frustration sets in. Also talk about gestures and facial expressions - what worked and what didn't. Describe how your boss came across to the average viewer, and to key stakeholders.
    
    Explain that there are strategies she can use to improve - even if your prior coaching and suggestions have gone unheeded.
    Offer to help, by providing more coaching, or by hiring a consultant like Next Solutions Group to do training and mock interviews. NSG has former senior-level journalists on its staff who create realistic mock interviews, complete with TV cameras and bright lights, and the same kind of questions and follow-ups you can expect from a reporter.
    
    Some executives will quickly realize the need for improvement and want to take whatever steps are necessary. Others will take time to come around. If your boss is in the latter category, give her time. Consider enlisting the help of other executives to persuade your boss that coaching is needed.
    
    Be patient but persistent. Eventually, your boss will likely see that her long-term success and her ability to excel in interviews are closely linked.`,


    post_classified_title: "What the Classified Document Probes Can Teach Us About Issues, Crisis & Reputation Management",
    post_classified_content: `Each day brings a new “breaking news” alert about the search for classified documents and endless discussions and comments about how the documents were handled. The seemingly endless public relations fallout highlights some key lessons about crisis and issues management that apply to individuals, corporations, and public officials alike.

    First: Don't let problems fester. Figure out how deep and how wide the issue has spread, and work on fixing the entire problem as quickly as possible. It's better to face one day or one period of negative press than a prolonged, drip of media assaults over days, weeks, or years.
    
    Second: Plan out your communications strategy. When faced with a serious reputational threat, it's tempting to take immediate action before coming up with a strategic foundation. But as explained in NSG Chair Bob Pearson's communications guidebook "Crafting Persuasion," the goal should be “strategy before execution,” so that your messaging comes out clear, consistent and constant. You don't want to rush to speak first and backpedal later.
    
    Third: Be as transparent and forthright as possible. Building and maintaining trust with your stakeholders and with the public is essential. When appropriate, aim to be transparent and forthright about what you know, what you don't know, and the steps you're taking to address the issue.
    
    Fourth: Communicate a simple and clear message. Even if your crisis involves complex or wonky issues, try to keep the message simple and understandable. Also, don't presume that your audience or the public won't “get it,” when you have a chance to explain what's going on. See it as an opportunity to take control of the narrative.
    
    Fifth: When appropriate, get help with your communications strategy and execution. Consider seeking the help of The Next Solutions Group experts in issues, crisis, and reputation management. `,



    post_tesla_title: "Tesla's Autopilot",
    post_tesla_content: `Tesla's Autopilot Part II
    
    When there are strong indications that a product may be responsible for deaths and injuries, companies should act right away. They shouldn't wait for government investigations, multipart investigative stories and lawsuits.
     
    The latter is the position Tesla finds itself in. This week, the EV manufacturer said it's recalling nearly all vehicles sold in the U.S. - about 2 million -- to update software and revamp a system that's supposed to make sure drivers are paying attention when using the Autopilot mode. This follows a two-year investigation by the National Highway Traffic Safety Administration that found flaws in the Autopilot system.
     
    The over-the-air recall will result in routine checks on drivers' attentiveness, and respond with warnings about paying attention to the road and keeping hands on the wheel when the Autopilot feature is activated.
     
    Companies should be proactive in recalling products - particularly when lives hang in the balance. They can build trust with customers by acting before being pressured to do so by the government or investigative news stories.
     
    When companies are seen taking voluntary steps to ensure customers' safety, that sends a positive message and helps build loyalty to the brand. Doing the opposite can fuel a negative perception that's difficult to reverse.
    
    ---
    
    Tesla Revelations Spotlight Considerations for Companies in Crisis

    Continuing news coverage of fatal accidents involving Tesla's highly-touted Autopilot feature- and the company's response--underscore the need to grapple with how much information to make public in a crisis.
    
    Crashes linked to Autopilot are at the center of civil lawsuits brought against Tesla and the feature also is the topic of a Justice Department investigation. There are at least 10 active lawsuits involving autopilot, according to the Washington Post, which recently detailed one case involving the death of a 50-year-old man in 2019 in Delray Beach, Florida. His Tesla Model 3 hit a tractor trailer, slicing off the roof, seconds after he'd activated Autopilot.
    
    The newspaper reported that there have been more than 700 U.S. crashes since 2014 involving Tesla in Autopilot mode. At least 19 of them were fatal.
    
    Tesla has been aggressive in marketing the Autopilot feature. But the company's critics, and relatives of those who have died in crashes, say the advertising significantly exaggerates the system's abilities and creates a false sense of safety.
    
    Tesla's reputational challenges are mounting. This week, the company disclosed in a Securities and Exchange Commission filing that it received a Justice Department subpoena related to Autopilot. The company also said the Justice Department sought information about “personal benefits, related parties, vehicle range, and personnel decisions” but didn't elaborate.
    
    “Should the government decide to pursue an enforcement action, there exists the possibility of a material adverse impact on our business, results of operation, prospects, cash flows, financial position, or brand,” the filing said.
    
    Tesla's chief executive, Elon Musk, insists that Autopilot is safer than cars driven by humans. He has rejected concerns over autopilot as “overblown” and has accused his critics of being Luddites.
    
    But Tesla has drawn criticism for releasing less information on crashes than in the past - increasing the reputational risk. The company, without explanation, stopped releasing safety data that compares the number of accidents per mile on autopilot mode to those off autopilot mode. The last report was in the fourth quarter of 2022.
    
    This situation is similar to the challenge many organizations encounter when faced with litigation and government investigations. Lawyers typically advise companies to release as little as possible to prevent new information from being used against them in court. Developing a communications plan in partnership with the legal team is key. It's important to find a balance between legal imperatives and releasing enough information to build trust with the public and mitigate the damage of the crisis.
    
    Decisions about how much information to release during a crisis need to be made on a case-by-case basis - with a clear understanding of the potential repercussions of each approach.
    `,

    post_child_labor_title: "Corporate Reputation and Child Labor",
    post_child_labor_content: `High on the list of reputational risks companies face: the use of child labor, either in their own factories, or in the factories of suppliers.

    Many large U.S. companies have been forced to confront this issue. In a series of articles over the last year, the New York Times documented numerous cases in which migrant kids, arriving in the U.S. in record numbers, have been working in jobs that risk their health and safety, in violation of labor laws.
    
    
    When it comes to monitoring their suppliers, the Times reported that many companies have outsourced this work to third-party inspectors who conduct private audits.
    
    The Times reported in December: “In the past two decades, private audits have become the solution to a host of public relations headaches for corporations. When scandal erupts over labor practices, or shareholders worry about legal risks, or advocacy groups demand a boycott, companies point to these inspections as evidence that they have eliminated abuses in their supply chains.”
    
    Yet the Times story found that many of these auditors have failed to catch illegal child labor during their inspections. Some inspectors told the newspaper they aren’t given enough time to thoroughly investigate or were pressured by their employers or companies to tone down their findings.
    
    
    We view third-party audits as a potentially useful tool – as long as the auditors are truly empowered to catch child labor and are contractually required to put in the time to detect it. Companies should view detecting child labor, and rejecting suppliers who rely on it, as an imperative – something that is essential to preserving their reputations and business.
    
    Companies must design approaches that are effective. That could mean sending their own employees to inspect suppliers’ plants if third-party auditors aren’t up to the task. Pointing to the existence of an audit is no longer enough to spare companies’ reputations when their products are shown to be made using child labor.`,

    post_boeing_title: `Boeing Mishaps Indicate Reputation Rehab Needed for Years to Come`,
    post_boeing_content: `The terror is hard to imagine: a refrigerator-sized “door plug” blows open at 16,000 feet, exposing Alaska Airline passengers to howling winds, sucking one boy’s shirt off and ripping cell phones out of people’s hands.

    Boeing, the maker of that 737 Max 9 jet in which this episode unfolded on Friday, followed a textbook crisis communications response. Boeing CEO Dave Calhoun told his staff the company will acknowledge its mistake, operate with 100% transparency and cooperate fully with investigators. This event “can never happen again,” Calhoun said. Days earlier, the company released a statement identifying safety as its top priority. Boeing expressed deep regret, and said it fully supported the FAA’s decision to require immediate inspection of the planes with the same configuration as the one that suffered the mishap.
    
    But this isn’t the first time the 737 Max line of jets has come under scrutiny over safety issues. The 737 Max 8 experienced two fatal crashes -- in 2018 and 2019. The issues in those crashes were unrelated to the door plug.
    
    After those episodes Boeing released hundreds of internal messages that included highly critical comments about the development of the 737 Max. One of them said the plane was “designed by clowns who in turn are supervised by monkeys.”
    
     
    From a communications perspective, these episodes point to the need for deep reputation rehabilitation for years to come.
    
     
    Whenever a product is involved in a fatal episode, the need for the manufacturer to repair its reputation, and that of its product, increases significantly. When there are multiple fatal or near fatal catastrophes, the damage increases exponentially.
    
     
    But before any company in such a situation can begin to rebuild its reputation – and the public’s trust -- it has to first ensure the underlying problems have been addressed. Until then, communications efforts will not be effective.`,


    post_dogfood_title: `Dog Food Rumors Show Corporate Reputation Risk`,
    post_dogfood_content: `Companies face increasing peril to their reputations – and sales -- as online rumors and misinformation proliferate.

    Take the example of Purina, the pet food maker.
    
    Complaints that its food has caused illnesses in pets have proliferated online in recent weeks. Those assertions took off on a Facebook group called Saving Pets One Pet @ A Time. The group administrator posted that she had received many reports of dogs or cats growing ill or dying after eating Purina Pro Plan. Posts on TikTok followed.
    
    Purina says there are no problems with its food.
    
    The online chatter caught the attention of NBC News, which wrote about the issue and quoted the Facebook group administrator claiming she has received 969 reports of dogs or cats growing ill after eating Purina food.
    
    The company told NBC the Facebook group hasn’t provided Purina with details about the complaints it received, and a company spokeswoman was quoted saying that a veterinarian who partners with another dog food brand is one of the Facebook group’s administrators. The veterinarian told NBC that she recommends multiple brands of pet food and that she isn’t trying to make money off of the Purina assertions.
    
    In such situations, companies need to respond aggressively and transparently on the platforms where the misinformation is spreading – with as many facts as they can muster to prove the allegations false.
    
    Unfounded allegations can spread so rapidly that responding quickly is essential. Additionally, there are usually a small group of people responsible for spreading rumors and misinformation. Companies need to identify them, determine their motivation, and address them directly.
    
    In this case, Purina could consider having samples of its food tested by a third party and posting the results online. Purina also could hire third-party investigators to examine its factories and make the findings public. And the company could consider responding online to all of those propagating the assertions, pressing for details that would allow it to investigate.
    
    Consumers need to see that companies are taking their concerns seriously and being forthcoming with their findings.`,

    post_robocall_title: `Fake Biden Robocall Illustrates Risks Companies Face`,
    post_robocall_content: `A robocall that appeared to be an AI-generated voice imitating President Joe Biden should serve as a fresh reminder to corporate executives of the peril they could face from deepfakes.

    The bogus Biden robocalls went to New Hampshire voters, urging them not to vote in the state’s primary, and instead to save their votes for the general election.
    
    The voice sounded like Biden’s and even employed words that he’s known to use, such as “malarkey.” Biden’s campaign said the voice was a fraud and asked the New Hampshire attorney general to investigate.
    
    Artificial intelligence allows computers to learn voices—which is particularly easy when there are online clips of someone giving speeches, interviews and other commentary. That technology is now readily available to anyone who wants to spread misinformation and disinformation.
    
    And that’s why companies need to take note.
    
    Businesses should have plans if someone creates a fake audio or video mimicking the CEO or other top executives making comments that could potentially damage the company and individuals’ reputations.
    
    Those plans should include a full-court press to counter the deepfake. Depending on the content of the impersonation, that could include a company statement, a statement from the executive in question—and a recitation of any past statements that contradict what the fake says. Companies should also familiarize themselves with the process for getting online platforms to remove fakes. They should have email addresses and templated requests for removal ready to go.
    
    And if the fake message is especially damaging, businesses should consider a long-term reputation campaign.`,

    post_sephora_title: "How The “10-Year-Olds at Sephora” Conversation Can Advance Corporate Reputation",
    post_sephora_content: `The phrase “10-year-olds at Sephora” is lighting up the Internet, sparking conversations about whether the beauty industry and social media are pushing unnecessary and potentially harmful products on young girls. Scroll through Tik Tok and you’ll see tweens giving make-up tutorials. Or visit stores like Sephora and you’ll likely see baby-faced girls sampling an array of make-up and skincare products. Dermatologists warn that some of these items, such as retinols, can be damaging to young skin and should only be used under a doctor’s supervision. There is also growing concern about the mental health impact of being exposed to unrealistic beauty standards at younger ages.

    While many stores sell beauty products, Sephora has been singled out by a trending phrase. When any company finds itself at the center of a controversy like this, it needs to carefully assess how to best protect and advance its corporate reputation. The actions it takes should first and foremost be guided by the company’s core values.
    
    Even if the situation is not company sponsored, but rather community or influencer driven, the company has an opportunity to lean into the controversary and play a leading role to address it.
     
    For example, Sephora should acknowledge the “10-year-olds at Sephora” conversation and consider the following steps:
    
    * Recognize that young girls are visiting their stores and potentially using products that are not formulated for them. The company could acknowledge the allure of the beauty industry to people of all ages but stress the importance of age-appropriate skincare.

    * Work with trusted experts to educate parents about which products are suitable for children and the ingredients, like retinol, to look out for.
    
    * Educate young people through TikTok and other social media channels using young brand ambassadors and influencers. Tweens can learn which skincare products are helpful to them, such as sunblock, and why some products are unnecessary or even damaging at their age.
    
    * Consider developing or selling a line of products specially formulated for young people. This could draw more tweens into the stores, reassure parents that they’re buying appropriate products for their kids and encourage brand loyalty from a wider range of customers.
    
     
    Not engaging in situations that hit at the heart of your company or industry can harm your corporate reputation. Instead, companies can view these flashpoint moments as opportunities to elevate their reputation, improve their business and show their customers and communities that they are listening to their concerns and they care.`,

    post_automakers_title: "Automakers Should Respond to Cheating Scandal With Transparency",
    post_automakers_content: `Recent revelations that Toyota Motor Corp. cheated on Japanese safety certification tests for seven vehicle models poses a significant reputational threat that the world’s top carmaker needs to quickly address.

    Toyota – along with Mazda Motor Corp., Honda Motor Co., Yamaha Motor Co. and Suzuki Motor Corp. – were found to have falsified or manipulated certification data. Toyota’s Daihatsu unit acknowledged last year that it rigged side-collision safety tests, mostly in cars sold under the Toyota brand.

    In a helpful statement, Toyota chairman Akio Toyoda has apologized for the scandal, in which the company used outdated or inadequate data for collision tests, along with incorrect testing of airbag inflation and rear-seat damage during crashes. Toyota suspended production in the country of three implicated models -- the Corolla Fielder, Corolla Axio and Yaris Cross – but said the safety of vehicles already on the road isn’t in question.

    The companies all said their products are safe. But the situation will raise questions among consumers about the companies’ honesty and reliability.

    When situations like this take place, companies need to be transparent about what took place and publicly disclose accurate data. Companies should also turn to third parties who can independently verify the accuracy of their data. Doing so would demonstrate a commitment to openness and accountability.

    In this instance, the automakers should be transparent about what happened and publicly detail the steps they’ve taken to prevent it from happening again – not just in Japan but in all markets where they sell vehicles.

    Transparent communication will be crucial in restoring confidence, protecting the interests of all stakeholders, and working toward renewed public trust.`,

    post_venmopublic_title: "The Dangers of Allowing Executives' Online Connections to Be Public",
    post_venmopublic_content: `
    Corporate executives – and anyone else in the public eye – should pay close attention to security settings that allow the public to view individuals to whom they're connected on social media and other sites. Allowing these lists to be public can pose significant risks.

    The ability to see who someone is connected to on platforms like LinkedIn, Facebook and Venmo can provide valuable insights into an individual's personal and professional life. This information can be exploited for various malicious purposes, including targeted phishing attacks, identity theft, blackmail, and even physical harm.

    Reporters can also access the information to write stories about people with whom newsworthy figures may have relationships. One example is a recent Wired story about the Venmo connections of U.S. Senator J.D. Vance, the GOP candidate for Vice President.

    For business executives, exposing their network can compromise sensitive company information. It could reveal potential business partners, investors, or employees, making them targets for cyberattacks or industrial espionage. Moreover, a compromised network can damage a company's reputation and erode trust among stakeholders.

    To mitigate these risks, it is imperative for public figures to prioritize robust social media security. This includes regularly reviewing and adjusting privacy settings on all platforms, limiting the visibility of friend lists, and being cautious about accepting connection requests. Additionally, using strong, unique passwords and enabling two-factor authentication are essential steps to protect personal information.

    By taking proactive measures to safeguard their digital footprint, individuals in the public eye can significantly reduce the risk of becoming victims of cybercrime and protect both their personal and professional interests.`,

};

export const AllThoughtPosts = [
    {date: "", title: THOUGHTS.post_venmopublic_title, content: THOUGHTS.post_venmopublic_content, images: ["venmopublic.webp"],linkedin: "https://www.linkedin.com/pulse/dangers-allowing-executives-online-connections-5wige/"},
    {date: "", title: THOUGHTS.post_automakers_title, content: THOUGHTS.post_automakers_content, images: ["automaker.webp"],linkedin: "https://www.linkedin.com/posts/the-next-solutions-group_automakers-should-respond-to-cheating-scandal-activity-7209705184220893184-sIlp?utm_source=share&utm_medium=member_desktop"},
    {date: "February 1st, 2024", title: THOUGHTS.post_sephora_title, content: THOUGHTS.post_sephora_content, images: ["sephora.webp"],linkedin: "https://www.linkedin.com/feed/update/urn:li:activity:7158821331046686720/"},
    {date: "January 23th, 2024", title: THOUGHTS.post_robocall_title, content: THOUGHTS.post_robocall_content, images: ["robocall.webp"],linkedin: "https://www.linkedin.com/feed/update/urn:li:activity:7155676958180188160/"},
    {date: "January 22th, 2024", title: THOUGHTS.post_dogfood_title, content: THOUGHTS.post_dogfood_content, images: ["dogfood.webp"],linkedin: "https://www.linkedin.com/feed/update/urn:li:activity:7155197470744997889/"},
    {date: "January 10th, 2024", title: THOUGHTS.post_boeing_title, content: THOUGHTS.post_boeing_content, images: ["boeing.webp"],linkedin: "https://www.linkedin.com/posts/the-next-solutions-group_reputationmanagement-crisismanagement-publictrust-activity-7150926154126823424-0Ha5?utm_source=share&utm_medium=member_desktop"},
    {date: "January 9th, 2024", title: THOUGHTS.post_child_labor_title, content: THOUGHTS.post_child_labor_content, images: ["labor.webp"],linkedin: "https://www.linkedin.com/posts/the-next-solutions-group_corporatereputation-privateaudits-endchildlabor-activity-7150488692321607680-uY3P?utm_source=share&utm_medium=member_desktop"},
    {date: "December 14th, 2023", title: THOUGHTS.post_tesla_title, content: THOUGHTS.post_tesla_content, images: ["tesla.webp"],linkedin: "https://www.linkedin.com/posts/the-next-solutions-group_electricvehicles-crisismanagement-teslacars-activity-7141134189143261185-OUfV?utm_source=share&utm_medium=member_desktop"},
    {date: "June 22, 2023", title: THOUGHTS.post_generative_ai_title, content: THOUGHTS.post_generative_ai_content, images: ["AI.webp"], linkedin: "https://www.linkedin.com/pulse/generative-ai-makes-reputation-management-more"},
    {date: "June 14, 2023", title: THOUGHTS.post_ai_title, content: THOUGHTS.post_ai_content, images: ["LawChatGPT.webp"], linkedin: "https://www.linkedin.com/posts/the-next-solutions-group_ai-lawfirm-reputationmanagement-activity-7078102473181155328-JhHT/?utm_source=share&utm_medium=member_ios"},
    {date: "June 14, 2023", title: THOUGHTS.post_nav_corp_partnerships_title, content: THOUGHTS.post_nav_corp_partnerships_content, images: ["PGATour.webp", "LIVGolf.webp"], linkedin: "https://www.linkedin.com/posts/the-next-solutions-group_leadership-reputationmanagement-partnerships-activity-7074744076876570624-xVNv/?utm_source=share&utm_medium=member_ios"},
    {date: "May 25, 2023", title: THOUGHTS.post_tipsjournalism_title, content: THOUGHTS.post_tipsjournalism_content, images: ["DanChilds.webp"], linkedin: "https://www.linkedin.com/pulse/so-youre-leaving-journalism-communications-tips-making-dan-childs/"},
    {date: "May 18, 2023", title: THOUGHTS.post_culturewars_title, content: THOUGHTS.post_culturewars_content, images: ["TargetLogo.webp","BudLightLogo.webp"], linkedin: "https://www.linkedin.com/posts/the-next-solutions-group_crisiscommunications-crisismanagement-reputationmanagement-activity-7067902085500952576-O6jf/?utm_source=share&utm_medium=member_ios"},
    {date: "May 11, 2023", title: THOUGHTS.post_mediatraining_title, content: THOUGHTS.post_mediatraining_content, images: ["ExecMeeting.webp"], linkedin: "https://www.linkedin.com/posts/the-next-solutions-group_executivemediatraining-mediatraining-reputationmanagement-activity-7064602854988075009-R_Vq/?utm_source=share&utm_medium=member_ios"},
    {date: "January 1, 2023", title: THOUGHTS.post_classified_title, content: THOUGHTS.post_classified_content, images: ["Classified.webp"],  linkedin: "https://www.linkedin.com/posts/the-next-solutions-group_issuesmanagement-crisiscommunications-reputationmanagement-activity-7027051453546393600-NJXM/?utm_source=share&utm_medium=member_ios"},
]

