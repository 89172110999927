import { Button, Container, List, ListItem, ListSubheader, Paper, TextField } from "@mui/material"
import { createContext, useContext, useEffect, useReducer, useState } from "react"

// MS Logins
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "../../authConfig";
import { callMsGraph, getGroups } from "./graph";

export const MSAuthContext = createContext()

const RenderGroup = ({group, children}) => {
    const msc = useContext(MSAuthContext)
    if (msc.reducerState.groups.filter(item => item === group).length > 0) {
        return <div>{ children }</div>
    } else {
        return <div></div>
    }
}

const dataReducer = (state, action) => {
    switch (action.type) {
        case "graphToken":
            return {
                ...state,
                groups: action.groups,
                graphData: action.graphData,
                graphToken: action.token,
            }
        case "authenticated":
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
            }
        case "accounts":
            return {
                ...state,
                accounts: action.accounts,
                tokenClaims: action.accounts[0]?.idTokenClaims,
                roles: action.accounts[0]?.idTokenClaims?.roles,
            }
        case "instance":
            return {
                ...state,
                instance: action.instance,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}

const initialState = {
    // mandatory
    instance: {},
    accounts: [],
    isAuthenticated: false,
    graphToken: {},
    graphData: {},
    tokenClaims: {},
    groups: [],

    roles: [],
}

export const MSAuthProvider = ({children}) => {
    const [ reducerState, reducerDispatch ] = useReducer(dataReducer, initialState)
    const { instance, accounts } = useMsal();


    // dispatch instance and accounts
    useEffect(() => {
        reducerDispatch({type: "instance", instance: instance})
    }, [instance])

    useEffect(() => {
        reducerDispatch({type: "accounts", accounts: accounts})
        if (accounts.length !== 0) {
            instance.setActiveAccount(accounts[0])
            const updateGraphToken = async () => {
                await instance.initialize()
                const graphToken = await instance.acquireTokenSilent({
                    scopes: [ "User.Read" ],
                    account: accounts[0],
                });

                const graphData = await callMsGraph(graphToken.accessToken).then(res => {
                    return res 
                })
                const allGroups = await getGroups(graphToken.accessToken).then(res => {
                    return res.value.filter(item => item["@odata.type"] === "#microsoft.graph.group").map(item => item.displayName)
                })
                reducerDispatch({type: "graphToken", token: graphToken, graphData: graphData, groups: allGroups})
            }
            updateGraphToken()
            reducerDispatch({type: "authenticated", isAuthenticated: true})
        }
    }, [accounts, instance])

    const state = {
        reducerState,
        RenderGroup
    }
    
    return (
        <MSAuthContext.Provider value={state}>
            { children }
        </MSAuthContext.Provider>
    )
}


export const TestAuthPage = () => {
    const msdp = useContext(MSAuthContext)


    if (msdp === undefined) return 
    return (
        <div>
            <Container>
                <div>
                    <h2>Test MS Authentication</h2>
                    { msdp.reducerState.isAuthenticated ? (
                            <>
                                <SignOutButton />
                                <ProfileData graphData={msdp.reducerState.graphData} />
                                <GroupRenders />
                            </>
                    ) : <>
                            <div style={{padding: '20px'}}>
                                <a href="/login">Login Test</a>
                            </div>

                            <SignInButton />
                            <ThoughtList />
                        </>
                    }
                </div>
            </Container>
        </div>
    )
}


export const GroupRenders = () => {

    return (
        <div style={{marginTop: '30px'}}>
            <h2>Group Renderings</h2>
            <RenderGroup group="NSGWebAdmin"><b>WebAdmin Authorized</b></RenderGroup>
            <RenderGroup group="NSGWebContentCreator"><b>NSGWebContentCreator Authorized</b></RenderGroup>
            <RenderGroup group="TEST"><b>TEST Authorized</b></RenderGroup>
            <br />

            <ThoughtList />
            <CreateThought />
        </div>
    )
}

export const ThoughtList = () => {

    return (
        <div>
            <h4>Thought List</h4>
        </div>
    )
}

export const CreateThought = () => {
    const [ thoughtTitle, setThoughtTitle ] = useState("")
    const [ thoughtDate, setThoughtDate ] = useState("")
    const [ thoughtImageURL, setThoughtImageURL ] = useState("")
    const [ thoughtContent, setThoughtContent ] = useState("")
    const [ thoughtShareURL, setThoughtShareURL ] = useState("")

    const handleChangeTitle = (e) => {
        setThoughtTitle(e.target.value)
    }
    const handleChangeDate = (e) => {
        setThoughtDate(e.target.value)
    }
    const handleChangeImageURL = (e) => {
        setThoughtImageURL(e.target.value)
    }
    const handleChangeContent = (e) => {
        setThoughtContent(e.target.value)
    }
    const handleChangeShareURL = (e) => {
        setThoughtShareURL(e.target.value)
    }

    return (
        <div>
            <Paper style={{padding: '10px', marginTop: '30px', marginBottom: '10px'}}>
                <h2>Test Create</h2>
                <TextField value={thoughtTitle} label="Title" onChange={handleChangeTitle} style={{margin:'10px'}} />
                <TextField value={thoughtDate} label="Date" onChange={handleChangeDate}  style={{margin:'10px'}}/>
                <TextField value={thoughtImageURL} label="ImageURL" onChange={handleChangeImageURL}  style={{margin:'10px'}} />
                <TextField value={thoughtContent} label="Content" onChange={handleChangeContent}  style={{margin:'10px'}} />
                <TextField value={thoughtShareURL} label="ShareURL" onChange={handleChangeShareURL}  style={{margin:'10px'}}/>
            </Paper>
        </div>
    )
}

export const SignInButton = () => {
    const { instance } = useMsal();

    const swaLogin = () => {
        fetch("/.auth/login/aad").then(res => {
            return res.json()
        }).then(json => {
            console.log(json)
        })
    }

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(async e => {
                console.log(e);

                const response = await instance.acquireTokenSilent({
                    scopes: ['api://5e04c168-ad7d-4801-a851-dcc3ffefedd6'],
                });
                // Make your protected API request using the acquired access token
                console.log(response.accessToken);
            });
        } else {
            instance.loginRedirect(loginRequest).catch(async e => {
                console.log(e);
            });
        }
    }
    return (
        <div>
            <Button variant="contained" onClick={() => handleLogin("popup")}>Sign In Popup</Button>
            <Button variant="contained" onClick={() => handleLogin("redirect")}>Sign In Redirect</Button>
            <Button variant="contained" onClick={() => swaLogin()}>Sign In SWA</Button>
        </div>
    )
}

export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/auth/ms",
            });
        }
    }

    return (
        <div style={{marginTop: '50px'}}>
            <Button variant="contained" onClick={() => handleLogout("redirect")}>Sign out</Button>
        </div>
    )
}


export const ProfileData = (props) => {
    const [ groupsF, setGroups ] = useState([])
    
    const msal = useContext(MSAuthContext)
    useEffect(() => {
        setGroups(msal.reducerState.groups)
    }, [msal.reducerState.groups])


    return (
        <div id="profile-div">
            <p><strong>First Name: </strong> {props.graphData.givenName}</p>
            <p><strong>Last Name: </strong> {props.graphData.surname}</p>
            <p><strong>Email: </strong> {props.graphData.userPrincipalName}</p>
            <p><strong>Id: </strong> {props.graphData.id}</p>

            <List>
                <ListSubheader>Groups Found</ListSubheader>
                { groupsF.map((item) => (
                    <ListItem key={item} style={{borderBottom: '1px solid #aaa'}}>{ item }</ListItem>
                ))}
            </List>
        </div>
    );
};
