import { Container, Grid } from "@mui/material"
import { useEffect} from 'react'
import { Fade } from "react-reveal"

export const PublicAffairsPage = () => {
    useEffect(() => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])
    return (
        <div>
            <Container>
                <Grid container padding={2}>
                    <Grid item xs={12} md={12}>
                        <Fade>
                            <h1>Public Affairs</h1>
                            <div>
                                Our approach is to engage and educate all relevant stakeholders. Our focus typically includes media, lawmakers and employees. We support client efforts to target key audiences with optimally crafted proactive and reactive strategies.
                            </div>
                            <div style={{textAlign: 'center', paddingTop: '50px'}}>
                                For more information, contact us at <a href="mailto:engage@nsg-global.com" className="lightBlueText" >engage@nsg-global.com</a>
                            </div>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}