import { Container, Grid } from "@mui/material"
import { useEffect} from 'react'
import { Fade } from "react-reveal"
import { Announcement, CenterFocusStrong, ConnectWithoutContact, Description,  Share, VoiceChat } from "@mui/icons-material";

export const EditorialPage = () => {
    useEffect(() => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])

    const centered = {display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '125px'}

    return (
        <div>
            <Container>
                <Fade>
                <Grid container padding={2} spacing={1}>


                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <h1>NSG Editorial Services</h1>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <h3>We offer a full range of editorial services to support your communications needs</h3>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid container>
                            { /* ======================= */ }
                            <Grid item xs={12} md={4} style={centered}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <Announcement />
                                    <h3>Press Release Text</h3>
                                </div>

                            </Grid>
                            <Grid item xs={12} md={4} style={centered}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <VoiceChat />
                                    <h3>Speech Writing</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={centered}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <CenterFocusStrong />
                                    <h3>Op-Eds</h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={4} style={centered}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <ConnectWithoutContact />
                                    <h3>Internal Communications</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={centered}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <Description />
                                    <h3>White papers</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={centered}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <Share />
                                    <h3>Social Media Posts</h3>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </Fade>
            </Container>
        </div>
    )
}
