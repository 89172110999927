import { useCallback, useEffect, useState } from 'react'
import { Container, Fade, Grid } from '@mui/material'
import { isMobile } from "react-device-detect";
import { HomeHelmet } from '../HelmetTags';
import { Link } from 'react-router-dom'

export const Home = () => {
    const [ hiddenBg, setHiddenBg ] = useState(false)
    const [ activeTracking, setActiveTracking ] = useState(false)

    const applyOverlay = useCallback((e) => {
        const oc = document.getElementById("overlayContainer");
        if (oc == null) {
            document.body.removeEventListener("mousemove", applyOverlay)
            return
        }
        oc.style.left = e.pageX + 'px';
        oc.style.top = e.pageY + 'px';
    }, [])

    useEffect(() => {
        if (activeTracking === true)  {
            document.body.addEventListener("mousemove", applyOverlay)
        } else {
            document.body.removeEventListener("mousemove", applyOverlay)
        }
    }, [activeTracking, applyOverlay])

    useEffect(() => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])


    const pageData = [
        { link: '/nsg/crisis-and-issues', text: "Crisis & Issues"},
        { link: '/nsg/business-intelligence', text: "Business Intelligence"},
        { link: '/nsg/public-affairs', text: "Public Affairs"},
        { link: '/nsg/cyber-security', text: "Cybersecurity & Investigations"},
        { link: '/nsg/media-engagement', text: "Media Engagement"},
        { link: '/nsg/executive-media', text: "Executive Media & Presentation Training"},
        { link: '/nsg/editorial-services', text: "Editorial Services"},
        { link: '/nsg/corporate-sponsorships', text: "Corporate Sponsorships"},
        
    ]

    const setAnimOff = () => {
        setActiveTracking(false)
    }
    const setAnimOn = () => {
        setActiveTracking(true)
    }

    // Hide image on mobile  
    const listenToScroll = () => {
        let heightToHideFrom = 500;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll > heightToHideFrom) {  
            if (isMobile) {
                setHiddenBg(true)
            }
        } else {
            setHiddenBg(false)
        }  
    };
    useEffect(() => {   
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll); 
    }, [])

    return (
        <Container>
        { HomeHelmet }

        <div style={{borderLeft: '1px solid #fff3', borderRight: '1px solid #fff3', overflow: 'hidden'}}>
            <div style={{height: '1200px'}}>

                { /* Test Overlay */ }
                <Fade in={activeTracking} timeout={500} >
                    <div id="overlayContainer" className='overlayHome' />
                </Fade>
                
                <Fade timeout={800}  in={true}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '10px'}}>
                        <h1 style={{fontFamily: 'Roboto', fontWeight: 'normal'}} className="dynHeader">Protecting & Advancing Reputation</h1>
                    </div>
                </Fade>
                <Grid container padding={2} style={{zIndex: 9}}>
                    { pageData.map(page => (
                        <Grid item xs={12} md={3} className='gridBox' style={{zIndex: 9}} onClick={() => {
                            document.body.removeEventListener("mousemove", applyOverlay)
                        }} key={page.link} onMouseOver={() => setAnimOn()} onMouseOut={() => setAnimOff()}>
                            
                            <Link to={page.link} className='destyle'>
                            <div className='gridBoxInternal'>
                                <h3>{ page.text }</h3>
                            </div>
                            </Link>
                        </Grid>
                    ))}


                    <div className='whatImageContainer'>
                        <Fade in={!hiddenBg} timeout={1000}>
                            <div>
                                <img src={process.env.PUBLIC_URL + "images/services-image.webp"} alt={'collective'} className={"whatImage rotating"} style={{minHeight: '500px', minWidth: '633px'}} />
                            </div>
                        </Fade>
                    </div>
                </Grid>
            </div>
            <div>
                <img src={process.env.PUBLIC_URL + '/images/reporter.webp'} alt="reporter" className='reportBG' />
            </div>
        </div>
        </Container>
    )
}
