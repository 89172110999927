import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Button } from '@mui/material'
import { Grid } from '@mui/material'
import ApartmentIcon from '@mui/icons-material/Apartment';
import { Link } from 'react-router-dom';

export const Footer = (props) => {
    return (
        <Grid container style={{paddingTop: '50px', borderTop: '1px solid #FFF3', paddingBottom: '70px'}} >
            <Grid item xs={12} md={6} style={{justifyContent: 'center', alignItems: 'center'}}>
                <div style={{textAlign: 'center', paddingBottom: '20px'}}>
                    <img src={process.env.PUBLIC_URL + "/images/nsg-logo2.webp"} alt="Next Solutions Group" className="logoDynamicFooter" />
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <a href="https://www.linkedin.com/company/the-next-solutions-group/" rel="noreferrer" style={{textDecoration: 'none'}} className="linkedIn" target="_blank">
                    <LinkedInIcon style={{fontSize: '50px'}} titleAccess="LinkedIn" />
                    </a>
                </div>
            </Grid>
            
            <Grid item xs={12} md={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <h4 style={{letterSpacing: '3px', textAlign: 'center', color: '#4DA8DA', borderBottom: '1px solid #FFF1', padding: '10px'}}>
                    <ApartmentIcon />
                </h4>
                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                    
                    { /* Col 1 */ }
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                        <div style={{display: 'flex', borderBottom: '1px solid #FFF3', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{fontSize: '0.8em !important', flexWrap: 'wrap', display: 'flex', justifyContent: 'center'}}>
                                <div style={{padding: '10px'}}>Austin</div>
                                <div style={{padding: '10px'}}>&#183;</div>
                                <div style={{padding: '10px'}}>New York</div> 
                                <div style={{padding: '10px'}}>&#183;</div>
                                <div style={{padding: '10px'}}>Washington, D.C.</div> 
                                <div style={{padding: '10px'}}>&#183;</div>
                                <div style={{padding: '10px'}}>London</div>
                            </div>
                        </div>
                    </div>
                </div>

            </Grid>

            <Grid container style={{marginTop: '100px'}}>
                <Grid item xs={12} md={12} style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                    { footerLinks.map((item) => {
                        return (
                            <Link to={item.href} style={{textDecoration: 'none'}} key={item.href} >
                            <Button
                                sx={{ margin: '5px', color: 'white', display: 'block', borderBottom: '1px solid transparent', 
                                      border: '1px solid #fff2', ':hover': {
                                    // theme.palette.primary.main
                                    color: '#fffc', backgroundColor: 'transparent !important', borderBottom: '1px solid #f57d05'
                                }}}  className="PageButton"
                            >{ item.title }</Button>
                            </Link>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}

const footerLinks = [
    { title: 'Home', href: '/' },
    { title: 'Who We Are', href: '/who' },
    { title: 'What We Do', href: '/what' },
    { title: 'Careers', href: '/careers' },
    { title: 'Contact', href: '/contact' }
]