import { Helmet } from "react-helmet"

export const HomeHelmet = (
    <>
        <Helmet>
            <link rel="canonical" href="https://nsg-global.com/" />
            <title>The Next Solutions Group</title>
        </Helmet>
    </>
)


export const QRGeneratorHelmet = (
    <>
        <Helmet>
            <link rel="canonical" href="https://nsg-global.com/qrgenerator" />
            <title>The Next Solutions Group | QR Generator</title>
        </Helmet>
    </>
)


export const WhoHelmetQR = (
    <>
        <Helmet>
            <link rel="canonical" href="https://nsg-global.com/" />
            <title>The Next Solutions Group - Who | QR</title>
        </Helmet>
    </>
)


export const WhoHelmet = (
    <>
        <Helmet>
            <link rel="canonical" href="https://nsg-global.com/who" />
            <title>The Next Solutions Group - Who</title>
        </Helmet>
    </>
)

export const WhatHelmet = (
    <>
        <Helmet>
            <link rel="canonical" href="https://nsg-global.com/what" />
            <title>The Next Solutions Group - What</title>
        </Helmet>
    </>
)

export const MediaHelmet = (
    <>
        <Helmet>
            <link rel="canonical" href="https://nsg-global.com/media" />
            <title>The Next Solutions Group - Media</title>
        </Helmet>
    </>
)

export const CareersHelmet = (
    <>
        <Helmet>
            <link rel="canonical" href="https://nsg-global.com/careers" />
            <title>The Next Solutions Group - Careers</title>
        </Helmet>
    </>
)

export const ContactHelmet = (
    <>
        <Helmet>
            <link rel="canonical" href="https://nsg-global.com/contact" />
            <title>The Next Solutions Group - Contact</title>
        </Helmet>
    </>
)
